import React from "react";
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';import AsyncPage from "./async";

export default [
  <DefaultConsumerLayout
    key="/consumer/features/ai-headshot-generator"
    exact
    path="/consumer/features/ai-headshot-generator"
    component={AsyncPage}
  />,
];