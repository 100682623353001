import React, {useState, useEffect, useContext} from 'react';
import headerUtils from '../../../utils/headerUtils';
import PFLink from '../../link';
import './appfeatures.css';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import webUtils from '../../../utils/webUtils';
import ArrowIcon from '../header/component/ArrowIcon';
import {AuthContext} from '../../auth';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import ButtonType from '../../strapi/buttonType';

export default function AppFeatures() {
  const {menu} = useContext(AuthContext);
  const [featureMenu, setFeatureMenu] = useState([]);
  const [index, setIndex] = useState('pe');
  const getLabel = (text, id) => {
    if (!webUtils.isMobile()) return text;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
          margin: '0 auto',
        }}
        onClick={() => {
          if (id === index) return setIndex('');
          setIndex(id);
        }}
      >
        <div>{text}</div>
        <ArrowIcon isShow={index === id} color="grey" />
      </div>
    );
  };

  useEffect(() => {
    if (_isEmpty(menu.filter(s => s.category === 'B2C Feature'))) {
      setFeatureMenu([
        {
          items: headerUtils.photoEditing,
          id: 'pe',
          title: (
            <Trans i18nKey="business:u.c.leftmenu.photo.editing">
              Photo Editing
            </Trans>
          ),
        },
        {
          items: headerUtils.portraitEditing,
          id: 'ped',
          title: (
            <Trans i18nKey="business:u.c.leftmenu.portrait.editing">
              Portrait Editing
            </Trans>
          ),
        },
        {
          items: headerUtils.videoEditing,
          id: 've',
          title: (
            <Trans i18nKey="business:u.c.leftmenu.video.editing">
              Video Editing
            </Trans>
          ),
        },
      ]);
      return;
    }
    const fm = menu.filter(s => s.category === 'B2C Feature')[0];

    const t =
      !_isEmpty(_get(fm, 'columns')) &&
      _get(fm, 'columns').map((s, i) => ({
        title: _get(s, 'items').filter(ss => ss.itemType === 'Group Title')[0]
          .itemTitle,
        label: _get(s, 'items').filter(ss => ss.itemType === 'Group Title')[0]
          .itemTitle,
        id: `featureMenu${i}`,
        items: _get(s, 'items').filter(ss => ss.itemType !== 'Group Title'),
        columnCTA: _get(s, 'columnCTA'),
      }));
    setFeatureMenu(t);
  }, [menu]);

  return (
    <div
      className={classnames({
        'pf-subheader-menu': !webUtils.isMobile(),
        'app-feature--menu': webUtils.isMobile(),
      })}
      style={{width: '100vw'}}
    >
      <div className="app-feature-menu--wrapper">
        {!_isEmpty(featureMenu) &&
          featureMenu.map((s, i) => (
            <div key={`feature-menu${i}`}>
              <div>
                <div
                  className={classnames({
                    'app-feature-menu--label': true,
                    'app-feature-menu--label--active':
                      webUtils.isMobile() && index === s.id,
                  })}
                >
                  {getLabel(s.title, s.id)}
                </div>
                {(index === s.id || !webUtils.isMobile()) && (
                  <div
                    className={classnames({
                      'app-feature-menu--link-container': true,
                      'app-feature-menu--label--active':
                        webUtils.isMobile() && index === s.id,
                    })}
                  >
                    {!_isEmpty(s.items) &&
                      s.items.slice(0, 10).map(ss => (
                        <PFLink
                          to={ss.itemUrl || ss.link}
                          key={ss.itemTitle || ss.text}
                          className={classnames({
                            'app-feature-menu--title-active':
                              window.location.pathname.indexOf(
                                ss.itemUrl || ss.link
                              ) !== -1,
                          })}
                        >
                          {ss.itemTitle || ss.text}
                        </PFLink>
                      ))}
                  </div>
                )}
                {!webUtils.isMobile() && _get(s, 'columnCTA') && (
                  <div className="text-center">
                    <ButtonType
                      index={_get(s, 'columnCTA.id')}
                      type={
                        _get(s, 'columnCTA.buttonType') ||
                        'Text with arrow icon'
                      }
                      link={_get(s, 'columnCTA.ctaLink')}
                      text={_get(s, 'columnCTA.ctaText')}
                      newTab={_get(s, 'columnCTA.newTab')}
                      nofollow={_get(s, 'columnCTA.nofollow')}
                      icon={_get(s, 'columnCTA.image.data.attributes.url')}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
