import React from "react";
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';import AsyncPage from "./async";

export default [
  <DefaultConsumerLayout
    key="/consumer/features/colorize-black-and-white-photos"
    exact
    path="/consumer/features/colorize-black-and-white-photos"
    component={AsyncPage}
  />,
];