import React, {Suspense, useEffect, useLayoutEffect, useState} from 'react';
import {Redirect, Switch, useHistory} from 'react-router-dom';
import {withRouter} from 'react-router';
import affiliateDao from './dao/affiliateDao';
import cookieUtils from './utils/cookieUtils';
import gtmUtils from './utils/gtmUtils';
import webUtils from './utils/webUtils';
import _isEmpty from 'lodash/isEmpty';
import routes from './pages/routes';
import storeUtils from './utils/storeUtils';
import TagManager from './TagManager';
// import TagManager from 'react-gtm-module';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import utmUtils from './utils/utmUtils';
import {notifyAPPWithCommand} from './pages/business/user/freetrial/component/step/notifyAPPWithCommand';
import _forEach from 'lodash/forEach';
// import CookieBanner from './utils/cookiebanner';

const tagManagerArgs = {
  gtmId: 'GTM-K6L9D76',
};

export default withRouter(({location}) => {
  const history = useHistory();
  const {i18n} = useTranslation();
  const params = new URLSearchParams(location.search);
  const [currentLang, setCurrentLang] = useState(
    storeUtils.getCurrentLanguage() || 'en'
  );
  const [pageReady, setPageReady] = useState(false);
  const url = window.location.href;
  const ref = params.get('ref') || '';
  const affiliate = params.get('affiliate') || '';
  const functionType = params.get('functionType') || '';
  const solutionType = webUtils.getInitialSolutionType();
  const utm_source = params.get('utm_source') || '';
  const utm_medium = params.get('utm_medium') || '';
  const utm_campaign = params.get('utm_campaign') || '';
  const utm_content = params.get('utm_content') || '';
  const utm_id = params.get('utm_id') || '';
  const utm_term = params.get('utm_term') || '';
  const pfFormId = params.get('pfFormId') || '';
  const coupon = params.get('coupon') || '';
  const signInWithApp = params.get('signInWithApp');
  const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  cookieUtils.setCookie('ref_code', ref);
  cookieUtils.setCookie('ref_source', utm_source);
  cookieUtils.setCookie('ref_medium', utm_medium);
  cookieUtils.setCookie('ref_campaign', utm_campaign);
  cookieUtils.setCookie('ref_content', utm_content);
  cookieUtils.setCookie('ref_id', utm_id);
  cookieUtils.setCookie('ref_term', utm_term);
  if (coupon) cookieUtils.setCookie('coupon', coupon, {expires});

  useEffect(() => {
    if (
      !webUtils.isWithGa() ||
      window.location.pathname.indexOf('/perfectbeauty/youcam') !== -1
    )
      return;
    TagManager.initialize(tagManagerArgs);
    // gtmUtils.loadGtagScript();
  }, []);

  useEffect(() => {
    if (webUtils.isInYmkApp()) {
      notifyAPPWithCommand(
        'disableBackForwardList',
        JSON.stringify({disable: signInWithApp === 'true'})
      );
    }
  }, []);
  const href = window.location.href;
  useEffect(() => {
    if (
      !webUtils.isInYmkApp() ||
      !pageReady ||
      href.indexOf('/business/app/sign-in') !== -1
    )
      return;
    setTimeout(() => {
      notifyAPPWithCommand('webReady');
    }, 1500);
  }, [href, pageReady]);

  window.onload = function() {
    setPageReady(true);
  };

  useEffect(() => {
    if (pfFormId) cookieUtils.setCookie('pfFormId', pfFormId);
  }, [pfFormId]);

  useEffect(() => {
    utmUtils.updateLandingUrl(url);
    utmUtils.updateLastUrlBeforeContactUs(url);
  }, [url]);

  useEffect(() => {
    if (!_isEmpty(affiliate)) {
      affiliateDao
        .queryAffiliate({affiliate, functionType, solutionType})
        .then(r => {
          if (!r) {
            return;
          }
          const expires = new Date(new Date().getTime() + 86400000);
          cookieUtils.setCookie('affiliationCode', affiliate, {expires});
          const now = new Date().getTime();
          const restDate =
            r.extensionDays || Math.ceil((r.fixedEndDate - now) / 86400000);
          const tmp = JSON.parse(
            cookieUtils.getCookie('affiliationDays') || '{}'
          );
          cookieUtils.setCookie(
            'affiliationDays',
            JSON.stringify({
              ...tmp,
              [[solutionType]]: restDate,
            }),
            {expires}
          );
          cookieUtils.setCookie(
            `${r.solutionType}_isBypassApproval`,
            r.isBypassApproval,
            {expires}
          );
        });
    }
  }, []);

  useEffect(() => {
    const lang = webUtils.getPathnameLang(location.pathname);
    setCurrentLang(lang);
  }, [location]);

  useEffect(() => {
    const utmParams = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_id,
      utm_term,
    };
    utmUtils.updateFirst(utmParams);
    utmUtils.updateLastTargetedAd(utmParams);
  }, [utm_source, utm_medium, utm_campaign, utm_content, utm_id, utm_term]);

  useEffect(() => {
    const unlisten = history.listen(location => {
      window.snapStore = null;
    });
    return function cleanup() {
      unlisten();
    };
  }, []);

  useEffect(() => {
    _forEach(document.getElementsByClassName('pf-root-hidden'), el =>
      el.classList.remove('pf-root-hidden')
    );
  }, []);

  return (
    <Suspense fallback={<div />}>
      <Helmet
        htmlAttributes={{
          lang: i18n.language,
        }}
      >
        <html
          lang={
            window.location.hostname.indexOf('makeupar') !== -1
              ? 'en-in'
              : i18n.language
          }
        />
      </Helmet>
      {/*<CookieBanner />*/}
      <Switch location={location}>
        <Redirect
          exact
          from={!currentLang || currentLang === 'en' ? `/` : `/${currentLang}`}
          to={
            !currentLang || currentLang === 'en'
              ? webUtils.isConsumerOnlyLanguage(currentLang)
                ? '/consumer'
                : '/business'
              : webUtils.isConsumerOnlyLanguage(currentLang)
              ? `/${currentLang}/consumer`
              : `/${currentLang}/business`
          }
        />
        {routes()}
      </Switch>
    </Suspense>
  );
});
