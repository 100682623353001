import React, {useEffect, useState} from 'react';
import './index.css';
import NFTHeader from '../../../../components/layout/header/nftheader';
import {Trans, useTranslation} from 'react-i18next';
import Container from '../../../../components/layout/container';
import Parser from 'react-html-parser';
import Link from '../../../../components/link';
import _isEmpty from 'lodash/isEmpty';
import useWindowWidth from '../../../../components/hooks/useWindowWidth';
import Sec4List from './compoment/sec4list/index';
import VisibilitySensor from 'react-visibility-sensor';
import _get from 'lodash/get';
import webUtils from '../../../../utils/webUtils';

export default function GBTFEvent({
  session,
  courseData,
  courseInfo,
  speaker,
  speakerId,
  languageType = 'enu',
}) {
  const isJaGbtf = languageType === 'jpn';
  const width = useWindowWidth();
  const {i18n} = useTranslation();
  const [activeText, setActiveText] = useState('');
  const language = webUtils.getLangPath(i18n.language);
  const [showTickets, setShowTickets] = useState(true);
  const [physicalTicketInfo, setPhysicalTicketInfo] = useState({});
  const [webinarTicketInfo, setWebinarTicketInfo] = useState({});
  const [ticketDesc, setTicketDesc] = useState('');
  useEffect(() => {
    if (!courseData.attrs || !_get(courseData, 'attrs.EVENT_TICKETS')) return;
    const tmp = JSON.parse(_get(courseData, 'attrs.EVENT_TICKETS'));
    setTicketDesc(tmp['ticketDesc']);
    setShowTickets(tmp['showTicket']);
    if (!_isEmpty(tmp['ticket'].filter(rrr => rrr.type === 'physical'))) {
      const tmp1 = tmp['ticket'].filter(rrr => rrr.type === 'physical')[0];
      tmp1.link = tmp1.isDynamicLinks
        ? !_isEmpty(
            tmp1.dynamicLinks.filter(rrr => rrr.utm === utm_medium && rrr.link)
          )
          ? tmp1.dynamicLinks.filter(
              rrr => rrr.utm === utm_medium && rrr.link
            )[0].link
          : tmp1.buttonLink
        : tmp1.buttonLink;
      setPhysicalTicketInfo(tmp1);
    }
    if (!_isEmpty(tmp['ticket'].filter(rrr => rrr.type === 'webinar'))) {
      const tmp2 = tmp['ticket'].filter(rrr => rrr.type === 'webinar')[0];
      tmp2.link = tmp2.isDynamicLinks
        ? !_isEmpty(
            tmp2.dynamicLinks.filter(rrr => rrr.utm === utm_medium && rrr.link)
          )
          ? tmp2.dynamicLinks.filter(
              rrr => rrr.utm === utm_medium && rrr.link
            )[0].link
          : tmp2.buttonLink
        : tmp2.buttonLink;
      setWebinarTicketInfo(tmp2);
    }
  }, [courseData, session]);
  const item = [
    {
      text: <Trans i18nKey="business:p.b.g.e.speakers">Speakers</Trans>,
      hashlink: true,
      link: `#speakers`,
      activeText: 'speakers',
    },
    {
      text: <Trans i18nKey="business:p.b.g.e.agenda">Agenda</Trans>,
      hashlink: true,
      link: `#agenda`,
      activeText: 'agenda',
    },
    {
      text: <Trans i18nKey="business:p.b.g.e.tickets">Tickets</Trans>,
      hashlink: true,
      link: `#tickets`,
      activeText: 'tickets',
    },
  ];

  const params = new URLSearchParams(window.location.search);
  const utm_medium = params.get('utm_medium') || '';
  const sec2Card = (icon, text, link, gtm) => {
    return (
      <div className="pf-gbtf--event-sec2-card-bgc">
        <Link
          className={`pf-gbtf--event-sec2-card-content ${gtm}`}
          to={link}
          isNewTab={true}
        >
          <img
            alt={''}
            className="pf-gbtf--event-sec2-card-content--icon"
            src={icon}
          />
          <div>{text}</div>
          <img
            alt={''}
            className="pf-gbtf--event-sec2-card-content--arrow"
            src={require('../../../../assets/images/business/gbtf/event/arrow.png')}
          />
        </Link>
      </div>
    );
  };
  const sec5Card = (icon, text, link, gtm) => {
    if (width <= 600) return sec2Card(icon, text, link, gtm);
    return (
      <div className="pf-gbtf--event-sec5-card-bgc">
        <Link
          className={`pf-gbtf--event-sec5-card-content ${gtm}`}
          to={link}
          isNewTab={true}
        >
          <img alt={''} width={90} src={icon} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>{text}</div>
            <img
              alt={''}
              className="pf-gbtf--event-sec2-card-content--arrow"
              src={require('../../../../assets/images/business/gbtf/event/arrow.png')}
            />
          </div>
        </Link>
      </div>
    );
  };

  function getBgImgSrc() {
    const src =
      width > 600 ? courseInfo.desktopBanner : courseInfo.mobileBanner;
    const imageLoader = new Image();
    imageLoader.src = src;
    return src;
  }

  const speakerCircle = (img, size, style, num) => {
    return (
      <div
        className="gbtf--event-speaker--cover"
        style={{width: size, height: size, ...style}}
      >
        <div style={{width: size - num, height: size - num, top: num / 2}}>
          <img
            alt=""
            src={img}
            width={size - (num + 4)}
            height={size - (num + 4)}
          />
        </div>
      </div>
    );
  };

  const sponsors = [
    {
      link: '/business',
      img: require('../../../../assets/images/business/gbtf/event/sponcer_PERFECT.svg'),
      show: true,
    },
    {
      link: '/consumer/apps/ymk',
      img: require('../../../../assets/images/business/gbtf/event/ico_ymk.svg'),
      show: true,
    },
  ];
  return (
    <div style={{overflowX: 'hidden'}}>
      <NFTHeader
        language={language}
        activeText={activeText}
        item={showTickets ? item : item.splice(0, 2)}
        is2b={true}
        menu={true}
        isEvent={true}
        showTickets={showTickets}
        utm_medium={utm_medium}
      />
      <div
        className="pf-gbtf--event-banner--bgc"
        style={{
          backgroundImage: getBgImgSrc()
            ? `url('${getBgImgSrc()}')`
            : 'initial',
        }}
        onClick={() => {
          window.location.href = isJaGbtf
            ? 'https://eventregist.com/e/tce5Simt7ZqN'
            : 'https://www.eventbrite.com/e/the-global-beauty-fashion-ai-forum-tickets-873513531487?aff=erelexpmlt';
        }}
      />
      <Container className="pf-gbtf--event-sec1-bgc">
        <div className="pf-gbtf--event-sec1-container">
          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) setActiveText('');
            }}
          >
            <h1 className="pf-gbtf--event-sec1-title">
              {Parser(courseData.courseTitle)}
            </h1>
          </VisibilitySensor>
          <div className="pf-gbtf--event-sec1-desc">
            {Parser(courseData.courseDesc)}
          </div>
        </div>
        <div className="pf-gbtf--event-sec1-card">
          <div>
            <div>
              <img
                alt=""
                src={require('../../../../assets/images/business/gbtf/event/ico_date.svg')}
              />
              <div>
                <Trans i18nKey="business:p.b.g.e.date">Date</Trans>
              </div>
            </div>
            <div>{courseInfo.courseDate && courseInfo.courseDate}</div>
          </div>
          <span className="pf-gbtf--event-sec1-card--border" />
          <div>
            <div>
              <img
                alt=""
                src={require('../../../../assets/images/business/gbtf/event/ico_time.svg')}
              />
              <div>
                <Trans i18nKey="business:p.b.g.e.time">Time</Trans>
              </div>
            </div>
            <div>{courseInfo.courseTime && courseInfo.courseTime}</div>
          </div>
          <span className="pf-gbtf--event-sec1-card--border" />
          <div>
            <div>
              <img
                alt=""
                src={require('../../../../assets/images/business/gbtf/event/ico_location.svg')}
              />
              <div>
                <Trans i18nKey="business:p.b.g.e.location">Location</Trans>
              </div>
            </div>
            <div>{courseInfo.courseLocation && courseInfo.courseLocation}</div>
          </div>
        </div>
      </Container>
      <div
        className="pf-gbtf--event-sec2-bgc"
        id="tickets"
        style={{display: !showTickets ? 'none' : 'block'}}
      >
        <Container mobileType={true}>
          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) setActiveText('tickets');
            }}
          >
            <h2 className="pf-gbtf--event-sec2-title">
              <Trans i18nKey="business:p.b.g.e.sec2.title">
                Tickets Available Now!
              </Trans>
            </h2>
          </VisibilitySensor>
          <div className="pf-gbtf--event-sec2-card--container">
            {physicalTicketInfo.buttonText &&
              sec2Card(
                require('../../../../assets/images/business/gbtf/event/ico_ticket1.svg'),
                physicalTicketInfo.buttonText,
                physicalTicketInfo.link,
                'gtm_pf-gbtf--event-sec2-card-content_Join-in-Person'
              )}
            {webinarTicketInfo.buttonText &&
              sec2Card(
                require('../../../../assets/images/business/gbtf/event/ico_ticket2.svg'),
                webinarTicketInfo.buttonText,
                webinarTicketInfo.link,
                'gtm_pf-gbtf--event-sec2-card-content_Join-Online'
              )}
          </div>
          <div className="pf-gbtf--event-sec2-desc">{Parser(ticketDesc)}</div>
        </Container>
      </div>
      {!_isEmpty(speaker) && (
        <div className="pf-gbtf--event-sec3-bgc" id="speakers">
          <Container mobileType={true}>
            <VisibilitySensor
              onChange={isVisible => {
                if (isVisible) setActiveText('speakers');
              }}
            >
              <div className="pf-gbtf--event-sec3-title">
                <Trans i18nKey="business:p.b.g.e.sec3.title">Speakers</Trans>
              </div>
            </VisibilitySensor>
          </Container>
          <Container className="gbtf--event-sec3-container" mobileType={true}>
            {!_isEmpty(speakerId) &&
              speakerId.map((s, index) => (
                <div
                  className="gbtf--event-speaker--block"
                  key={`gbtf--speaker${index}`}
                >
                  {s && speaker[s] && speaker[s].speakerImageUrl && (
                    <>
                      {speakerCircle(
                        speaker[s].speakerImageUrl,
                        width > 600 ? 200 : 130,
                        {
                          margin: '0 auto 12px',
                        },
                        8
                      )}
                    </>
                  )}
                  {s && s && speaker[s] && speaker[s].brandImageUrl && (
                    <div className="gbtf--event-speaker--brand">
                      <img alt="" src={speaker[s].brandImageUrl} />
                    </div>
                  )}
                  <div className="gbtf--event-speaker--speakername">
                    {s && speaker[s] && speaker[s].speakerName}
                  </div>
                  <div className="gbtf--event-speaker--speakerPosition">
                    {s && speaker[s] && speaker[s].speakerPosition}
                  </div>
                </div>
              ))}
          </Container>
          {!_isEmpty(speakerId) && speakerId.length > 6 && (
            <img
              className="pf-gbtf--event-sec3-mobile--bottom-image"
              alt=""
              src={
                'https://plugins-media.makeupar.com/smb/story/2022-04-12/3dd04285-7a03-40d0-af2d-6553dbe7a936.jpg'
              }
            />
          )}
          {languageType !== 'fra' && (
            <div className="pf-gbtf--event-sec4-detail">
              <Trans i18nKey="business:p.b.g.e.sec4.more.detail">
                Programming To Be Announced Soon
              </Trans>
            </div>
          )}
        </div>
      )}
      {!_isEmpty(session) && (
        <Container
          mobileType={true}
          className="pf-gbtf--event-sec4-bgc"
          id="agenda"
        >
          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) setActiveText('agenda');
            }}
          >
            <div className="pf-gbtf--event-sec4-title">
              <Trans i18nKey="business:p.b.g.e.sec4.title">Agenda</Trans>
            </div>
          </VisibilitySensor>
          <div>
            <div className="pf-gbtf--event-sec4-date">
              {courseInfo.courseDate && courseInfo.courseDate}
            </div>
            {session.map((s, index) => (
              <div
                className="pf-gbtf--event-sec4-list--bottom"
                key={`pf-gbtf--event-sec4-list${index}`}
              >
                <Sec4List
                  data={s}
                  speakerCircle={speakerCircle}
                  lang={courseData.languageType}
                />
              </div>
            ))}
          </div>
          {languageType !== 'fra' && (
            <div className="pf-gbtf--event-sec4-detail">
              <Trans i18nKey="business:p.b.g.e.sec4.more.detail">
                Programming To Be Announced Soon
              </Trans>
            </div>
          )}
        </Container>
      )}
      <div
        className="pf-gbtf--event-sec5-bgc"
        style={{
          display:
            !showTickets || (_isEmpty(speaker) && _isEmpty(session))
              ? 'none'
              : 'block',
        }}
      >
        <Container mobileType={true}>
          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) setActiveText('');
            }}
          >
            <div className="pf-gbtf--event-sec3-title">
              <Trans i18nKey="business:p.b.g.e.sec5.title">
                Tickets Available Now!
              </Trans>
            </div>
          </VisibilitySensor>
          <div className="pf-gbtf--event-sec2-card--container">
            {physicalTicketInfo.buttonText &&
              sec5Card(
                require('../../../../assets/images/business/gbtf/event/ico_ticket1.svg'),
                physicalTicketInfo.buttonText,
                physicalTicketInfo.link,
                'gtm_pf-gbtf--event-sec5-card-content_Join-in-Person'
              )}
            {webinarTicketInfo.buttonText &&
              sec5Card(
                require('../../../../assets/images/business/gbtf/event/ico_ticket2.svg'),
                webinarTicketInfo.buttonText,
                webinarTicketInfo.link,
                'gtm_pf-gbtf--event-sec5-card-content_Join-Online'
              )}
          </div>
        </Container>
      </div>
      {languageType !== 'fra' && (
        <Container className="pf-gbtf--event-sec6-bgc" mobileType={true}>
          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) setActiveText('');
            }}
          >
            <div
              className="pf-gbtf--event-sec3-title"
              style={{marginTop: showTickets ? '50px' : 0}}
            >
              <Trans i18nKey="business:p.b.g.e.sec6.title">Sponsors</Trans>
            </div>
          </VisibilitySensor>
          <div className="pf-gbtf--event-sec6-block">
            {sponsors.map((s, index) => (
              <div
                key={`sponsor${index}`}
                style={{display: s.show ? 'block' : 'none'}}
              >
                <Link
                  to={s.link}
                  isNewTab={true}
                  re={'nofollow noopener noreferrer'}
                >
                  <img alt={''} src={s.img} />
                </Link>
              </div>
            ))}
          </div>
          {languageType === 'enu' && (
            <div className="pf-gbtf--event-sec7-container">
              <div className="pf-gbtf--event-sec7-title">
                For more information on becoming a sponsor please contact us at:
                <br />
                <a href="mailto:contact_pr@perfectcorp.com?subject=Global%20Beauty%20and%20Fashion%20AI%20Forum%20Sponsorship&body=Hi%2C%20%0A%0AI%20am%20interested%20in%20becoming%20a%20sponsor%20for%20the%20Global%20Beauty%20and%20Fashion%20AI%20Forum.%20Here%20are%20my%20details%3A%0A%0AName%3A%0ATitle%3A%0ACompany%3A%0A%0APlease%20reach%20out%20to%20discuss%20this%20further.%20%0A%0A%0A">
                  contact_pr@perfectcorp.com
                </a>
              </div>
            </div>
          )}
        </Container>
      )}
    </div>
  );
}
