import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from "../../../../components/layout/DefaultConsumerLayout";

export default [
  <DefaultConsumerLayout
    key="/consumer/apps/"
    exact
    path="/consumer/apps/:appType"
    component={AsyncPage}
  />,
];
