import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from "../../../components/layout/DefaultConsumerLayout";

export default [
  <DefaultConsumerLayout
    key="/consumer/menus"
    exact
    path="/consumer/menus"
    component={AsyncPage}
  />,
];
