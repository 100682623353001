import React, {useEffect, useState} from 'react';
import Seo from '../../../../components/seo/index';
import {useTranslation} from 'react-i18next';
import useWindowWidth from '../../../../components/hooks/useWindowWidth';
import _isEmpty from 'lodash/isEmpty';
import PrerenderLayout from '../../../../components/layout/PrerenderLayout';
import LoadingPage from '../../../../components/loadingpage';
import Strapi from '../../../../components/strapi';
import GetStrapiContent from '../../../../components/strapi/getstrapicontent';
import Pricing from '../../plan/pricing/async';
import webUtils from '../../../../utils/webUtils';
import _get from 'lodash/get';
import StrapiUtils from '../../../../components/strapi/strapiUtils';

export default function OnlineService({match: {params}, location}) {
  const [pageType, setPageType] = useState(
    location.pathname.indexOf('plan') !== -1 ? 'plan' : 'feature'
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const {i18n, t} = useTranslation();
  const width = useWindowWidth();
  const {strapiContent} = GetStrapiContent(
    webUtils.getOnlineServiceLink().strapiEnum,
    setIsLoaded,
    location,
    true
  );
  const content =
    !_isEmpty(strapiContent) &&
    (pageType === 'feature' ? strapiContent : strapiContent.slice(0, 1));

  useEffect(() => {
    if (location.pathname.indexOf('/plan') !== -1) {
      setPageType('plan');
    } else {
      setPageType('feature');
    }
  }, [location.pathname]);

  if (!isLoaded || _isEmpty(strapiContent))
    return (
      <PrerenderLayout>
        <LoadingPage />
      </PrerenderLayout>
    );
  const seoData = StrapiUtils.getTopBannerText(strapiContent[0]);

  return (
    <PrerenderLayout
      showContactSales={false}
      showDemoStore={false}
      showOnlineService={true}
      showFreeTrial={true}
    >
      <Seo
        key="seo"
        title={_get(seoData, 'title')}
        description={_get(seoData, 'desc')}
        image={_get(seoData, 'image')}
      />
      {content.map((s, i) => (
        <div key={`online-service-strapi-sec${i}`}>
          <Strapi
            content={s}
            width={width}
            productPage={'online-service'}
            i18n={i18n}
            pageType={pageType}
            setPageType={setPageType}
          />
        </div>
      ))}
      {pageType === 'plan' && isLoaded && !_isEmpty(strapiContent) && (
        <Pricing location={location} match={{params: params}} />
      )}
    </PrerenderLayout>
  );
}
