import React, {useContext, useEffect, useState} from 'react';
import Link from '../../../components/link';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import webUtils from '../../../utils/webUtils';
import headerUtils from '../../../utils/headerUtils';
import _get from 'lodash/get';
import PinkButton from '../../button/pinkbutton';
import './Menus.css';
import {AuthContext} from '../../auth';
import ButtonType from '../../strapi/buttonType';
import _isEmpty from 'lodash/isEmpty';

export default function Menus({onClick}) {
  const {location} = useReactRouter();
  const {menu} = useContext(AuthContext);
  const [_2bMenu, set_2bMenu] = useState([]);
  const isActive = paths => webUtils.isSublink(location.pathname, paths);
  const LinkGroup = ({groupName, items, onClick, location}) => {
    return (
      <>
        <div className="pf-subheader-pop-menu__subtitle">{groupName}</div>
        {items.map(item => (
          <Link
            to={item.link}
            onClick={onClick}
            className={classnames({
              'pf-subheader-menu__link--active': isActive(item.link),
              'pf-subheader-menu__link': true,
            })}
            key={item.link}
            isWanna={_get(item, 'isWanna')}
          >
            {item.text}
            {_get(item, 'isWanna') && (
              <img
                width={13}
                style={{marginLeft: '5px'}}
                alt=""
                src={require('../../../assets/images/icon/open_window_ico.svg')}
              />
            )}
          </Link>
        ))}
      </>
    );
  };
  const getItem = val => {
    if (val.itemType === 'Group Title') {
      return (
        <div className="pf-subheader-pop-menu__subtitle">{val.itemTitle}</div>
      );
    }
    return (
      <Link
        to={_get(val, 'itemUrl')}
        onClick={onClick}
        className={classnames({
          'pf-subheader-menu__link--active': isActive(_get(val, 'itemUrl')),
          'pf-subheader-menu__link': true,
        })}
        key={_get(val, 'itemTitle')}
      >
        {val.itemTitle}
        {_get(val, 'showIcon') && (
          <img
            width={13}
            style={{marginLeft: '5px'}}
            alt=""
            src={require('../../../assets/images/icon/open_window_ico.svg')}
          />
        )}
      </Link>
    );
  };
  const getMenu = () => {
    if (!_get(_2bMenu, 'columns'))
      return (
        <>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.makeup">Makeup</Trans>
              }
              items={headerUtils.makeupProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.eyewear">
                  Eyewear
                </Trans>
              }
              items={headerUtils.eyewearProducts}
              onClick={onClick}
              location={location}
            />
          </div>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.face.diagnostic">
                  Face Diagnostic
                </Trans>
              }
              items={headerUtils.faceDiaProducts}
              onClick={onClick}
              location={location}
            />
          </div>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.hair.color">
                  Hair
                </Trans>
              }
              items={headerUtils.hairProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.nail.color">
                  Nail
                </Trans>
              }
              items={headerUtils.nailProducts}
              onClick={onClick}
              location={location}
            />
          </div>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.jewelry">
                  Jewelry and Watches
                </Trans>
              }
              items={headerUtils.jewelryWatchesProducts}
              onClick={onClick}
              location={location}
            />
          </div>
          <div className="pf-subheader-menu__menus">
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.accessories">
                  Accessories
                </Trans>
              }
              items={headerUtils.accessoriesProducts}
              onClick={onClick}
              location={location}
            />
            <LinkGroup
              groupName={
                <Trans i18nKey="business:c.l.s.popupmenu.beard.dye">
                  Men's grooming
                </Trans>
              }
              items={headerUtils.bearddyeProducts}
              onClick={onClick}
              location={location}
            />
          </div>
        </>
      );
    return _get(_2bMenu, 'columns').map((s, ii) => (
      <div
        className="pf-subheader-menu__menus"
        style={{flexBasis: `${100 / _get(_2bMenu, 'columns').length}%`}}
      >
        {s.items.slice(0, 10).map((ss, i) => (
          <div key={`menu-item${i}`}>{getItem(ss)}</div>
        ))}
        <ButtonType
          index={ii}
          type={_get(s, 'menuCTA.buttonType') || 'Text with arrow icon'}
          link={_get(s, 'menuCTA.ctaLink')}
          text={_get(s, 'menuCTA.ctaText')}
          newTab={_get(s, 'menuCTA.newTab')}
          nofollow={_get(s, 'menuCTA.nofollow')}
          icon={_get(s, 'menuCTA.image.data.attributes.url')}
        />
      </div>
    ));
  };
  const getMenuCta = () => {
    if (!_get(_2bMenu, 'menuCTA'))
      return (
        <>
          <PinkButton
            text={
              <Trans i18nKey="business:c.l.f.menudefinitions.contact.sales">
                Contact Sales
              </Trans>
            }
            link={'/business/contact-us/sales'}
            className="pf-subheader-menu__button"
            square={true}
          />
          <PinkButton
            bgColor={'white'}
            text={
              <Trans i18nKey="business:c.l.f.menudefinitions.try.our.demo">
                TRY OUR DEMO
              </Trans>
            }
            func={webUtils.goToDemostore}
            className="pf-subheader-menu__button"
            square={true}
          />
          <Link
            to="/business/solutions/enterprise"
            className="pf-subheader-menu__viewall"
            onClick={onClick}
          >
            <Trans i18nKey="business:p.b.s.e.s.view.all">
              View all products
            </Trans>
          </Link>
        </>
      );

    return _get(_2bMenu, 'menuCTA').map((s, i) => (
      <ButtonType
        index={i}
        type={_get(s, 'buttonType') || 'Text with arrow icon'}
        link={_get(s, 'ctaLink')}
        text={_get(s, 'ctaText')}
        newTab={_get(s, 'newTab')}
        nofollow={_get(s, 'nofollow')}
        icon={_get(s, 'image.data.attributes.url')}
        className={'pf-subheader-menu__button'}
      />
    ));
  };
  useEffect(() => {
    if (_isEmpty(menu.filter(s => s.category === 'B2B Enterprise'))) return;
    set_2bMenu(menu.filter(s => s.category === 'B2B Enterprise')[0]);
  }, [menu]);

  return (
    <div
      className="pf-subheader-menu"
      style={{width: '100vw', paddingBottom: 0}}
    >
      <div className="pf-subheader-menu--container">
        <div className="pf-subheader-menu__left">
          {webUtils.isMobile() ? (
            <div className="mobile-business-menu__title">
              <Trans i18nKey="business:p.b.s.e.s.menu.services">Services</Trans>
            </div>
          ) : (
            <div>
              <div className="pf-subheader-pop-menu-title">
                {_get(_2bMenu, 'menuTitle') || (
                  <Trans i18nKey="business:p.b.s.e.s.menu.enterprise.services">
                    Enterprise Services
                  </Trans>
                )}
              </div>
              <div className="pf-subheader-pop-menu-desc">
                {_get(_2bMenu, 'menuDescription') || (
                  <Trans i18nKey="business:p.b.s.e.s.menu.enterprise.services.desc">
                    Our most powerful AI & AR beauty, fashion, and skin tech
                    solutions for larger & multi-nation brands with complex
                    requirements and maximum scale
                  </Trans>
                )}
              </div>
            </div>
          )}
          <div style={{display: webUtils.isMobile() ? 'block' : 'flex'}}>
            {getMenu()}
          </div>
          <div className="pf-subheader-menu__button-wrapper">
            {getMenuCta()}
          </div>
        </div>
      </div>
    </div>
  );
}
