import React, {useEffect, useState} from 'react';
import authDao, {cache} from '../../dao/authDao';
import dao from '../../dao/supportDao';
import webUtils from '../../utils/webUtils';
import strapiDao from '../../dao/strapiDao';
import langCode from '../../utils/langCode';
import {useTranslation} from 'react-i18next';
import _get from 'lodash/get';

export const AuthContext = React.createContext({
  accountSummary: null,
  isFirstLoaded: false,
  isLoaded: false,
  isSignIn: false,
  countryCode: '',
  menu: [],
});

export const withAuth = Component => props => (
  <AuthContext.Consumer>
    {value => <Component {...props} auth={value} />}
  </AuthContext.Consumer>
);

export default function Auth({children}) {
  const params = new URLSearchParams(window.location.search);
  const {i18n} = useTranslation();
  const [isFirstLoaded, setIsFirstLoaded] = useState(cache.isFirstLoaded);
  const [isLoaded, setIsLoaded] = useState(!!cache.accountSummary);
  const [accountSummary, setAccountSummary] = useState(cache.accountSummary);
  const [countryCode, setCountryCode] = useState(cache.countryCode);
  const [menu, setMenu] = useState(cache.menu);
  const cmsId = params.get('cmsId');
  const isBusiness = webUtils.isBusiness();
  const languageType = langCode.convertLanguageCode(i18n.language || 'en');
  const isEnd = webUtils.isMakeupArUrl() && languageType === 'enu';

  useEffect(() => {
    if (!countryCode) {
      dao
        .ipCountry()
        .then(res => {
          cache.countryCode = res;
          setCountryCode(res);
        })
        .catch(error => {
          console.log('location error: ', error);
          setCountryCode('');
        });
    }
    if (!accountSummary) {
      authDao
        .loadAuth()
        .then(json => {
          cache.accountSummary = {...json};
          cache.isFirstLoaded = true;
          setIsFirstLoaded(true);
          setAccountSummary(json);
          setIsLoaded(true);
        })
        .catch(() => {
          cache.isFirstLoaded = true;

          setIsFirstLoaded(true);
          setIsLoaded(true);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      cmsId &&
      (window.location.pathname.indexOf('/business/menus') !== -1 ||
        window.location.pathname.indexOf('/consumer/menus') !== -1)
    ) {
      strapiDao
        .getProductPageById({
          type: isBusiness ? 'BUSINESS_MENU' : 'CONSUMER_MENU',
          id: cmsId,
        })
        .then(res => {
          if (!res) return;
          const mm = _get(res, 'attributes.sections');
          setMenu(mm);
        })
        .catch(err => {
          console.log({err});
        });
      return;
    }
    strapiDao
      .getProductPageByLanguage({
        type: isBusiness ? 'BUSINESS_MENU' : 'CONSUMER_MENU',
        languageType: isEnd ? 'end' : languageType,
        defaultEnu: true,
      })
      .then(res => {
        if (!res) return;
        const mm = _get(res, 'attributes.sections');
        setMenu(mm);
      });
  }, [isBusiness]);

  return (
    <AuthContext.Provider
      value={{
        accountSummary,
        isFirstLoaded,
        isLoaded,
        isSignIn: !!accountSummary,
        countryCode,
        menu,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
