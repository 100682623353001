import React, {useState, useRef, useEffect} from 'react';
import './index.css';
import ProductBannerButton from '../button';
import useWindowWidth from '../../hooks/useWindowWidth';
import '../index.css';
import pathUtils from '../../../utils/pathUtils';
import {Trans, useTranslation} from 'react-i18next';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import ArrowLink from '../../button/arrowlink';
import webUtils from '../../../utils/webUtils';
import _get from 'lodash/get';
import FreeTrialButton from '../../button/freetrialbutton';
import PinkButton from '../../button/pinkbutton';
import ReactPlayer from 'react-player';
import EnterpriseButtons from './component/enterprisebuttons';
import {VisibilityObserver} from 'reactjs-visibility';
import QRCode from 'qrcode.react';
import OnlineServiceSwitchBtn from './component/onlineserviceswitchbtn';
import GetTrialPlan from '../../hooks/getTrialPlan';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade} from 'swiper';
import '../../../pages/business/home/rotatebanner/index.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';

export default function ProductVideoBanner({
  desktopVideo,
  mobileVideo,
  desktopPoster,
  mobilePoster,
  title,
  desc,
  theme = 'dark',
  gtmClassNamePrefix = '',
  extraButtons = null,
  buttonConfig = {},
  bgClassName,
  version = '',
  aspectratio,
  enhancedTitle = '',
  ctaText,
  superscript,
  buttons = [],
  setShow = () => {},
  pageType,
  setPageType,
  rotateBanner = [],
}) {
  const deviceSpecificBanners = webUtils.isMobile()
    ? rotateBanner.filter(s => s.dpi === 'mobile')
    : rotateBanner.filter(s => s.dpi === '100');

  const [autoplay, setAutoplay] = useState(
    window.navigator.userAgent !== 'ReactSnap'
  );
  const [qrLink, setQrLink] = useState(false);
  const handleChangeVisibility = visible => {
    setShow(!visible);
  };
  const swiperRef = useRef(null);
  const [index, setIndex] = useState(0);
  const width = useWindowWidth();
  const {i18n} = useTranslation();
  const isSmb = webUtils.isSmb();
  const {autoFreeTrial, isSignIn} = GetTrialPlan();
  const functionType = webUtils.getOnlineServiceLink().functionType;
  const isOnlineService =
    window.location.pathname.indexOf('/online-service') !== -1;

  const buttonsCollections = () => {
    return (
      <div className="pf-product__banner--buttons-pad-container">
        {isSmb && isSignIn && (
          <>
            <div className="pf-product__banner--btn">
              {autoFreeTrial && isSmb ? (
                <div>
                  <FreeTrialButton
                    className={'pf-product__banner--btn-style'}
                    gtm={'gtm_header_menubar_free_trial'}
                    style={{
                      margin: !webUtils.isMobile()
                        ? '0 15px 0 0'
                        : '0 auto 15px',
                    }}
                    bgColor={'pink'}
                  />
                </div>
              ) : (
                <div>
                  <PinkButton
                    func={() => {
                      const elem = document.querySelector('#switchBtnAnchor');
                      elem &&
                        elem.scrollIntoView({
                          behavior: 'instant',
                          block: 'start',
                        });
                    }}
                    isNewTab={false}
                    className="pf-product__banner--btn-style"
                    text={
                      <Trans i18nKey="business:p.b.h.solutions.smb.index.subscribe">
                        Subscribe Now
                      </Trans>
                    }
                    link={webUtils.getOnlineServiceLink().plan}
                    gtm={`${buttonConfig[1].gtm}_${webUtils.determineLanguage(
                      i18n.language
                    )}`}
                    square={true}
                    bgColor={'pink'}
                    style={{
                      margin: !webUtils.isMobile()
                        ? '0 15px 0 0'
                        : '0 auto 15px',
                    }}
                  />
                </div>
              )}
              {buttonConfig[1].buttonType === 'Image with QRcode' ? (
                <div
                  onMouseOver={() => {
                    setQrLink(true);
                  }}
                  onMouseOut={() => {
                    setQrLink(false);
                  }}
                >
                  <PinkButton
                    isNewTab={buttonConfig[1].newTab}
                    className="pf-product__banner--btn-style"
                    text={buttonConfig[1].text}
                    href={buttonConfig[1].link}
                    gtm={`${buttonConfig[1].gtm}_${webUtils.determineLanguage(
                      i18n.language
                    )}`}
                    square={true}
                    bgColor={'white'}
                  />
                  {buttonConfig[1].link && qrLink && width > 1024 && (
                    <div className="text-center">
                      <div className={'qr-code-block'}>
                        <QRCode
                          size={100}
                          bgColor={'white'}
                          value={buttonConfig[1].link}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {buttonConfig[1].text && (
                    <PinkButton
                      isNewTab={buttonConfig[1].newTab}
                      className="pf-product__banner--btn-style"
                      text={buttonConfig[1].text}
                      href={buttonConfig[1].link}
                      gtm={`${buttonConfig[1].gtm}_${webUtils.determineLanguage(
                        i18n.language
                      )}`}
                      square={true}
                      bgColor={'white'}
                    />
                  )}
                </div>
              )}
            </div>
            {extraButtons}
          </>
        )}
        {(!isSmb || !isSignIn) && (
          <ProductBannerButton
            buttonConfig={buttonConfig}
            theme={theme}
            extraButtons={extraButtons}
            gtmClassNamePrefix={gtmClassNamePrefix}
          />
        )}
        <EnterpriseButtons buttons={buttons} />
        {!_isEmpty(ctaText) && (
          <>
            {ctaText.map((s, i) => (
              <div
                key={`topbanner-${i}`}
                className={classnames({
                  'text-center': width <= 1024,
                })}
                style={{marginTop: '15px'}}
              >
                <ArrowLink
                  newTab={s.newTab}
                  title={s.ctaText}
                  link={s.ctaLink}
                  imgStyle={{width: '22px'}}
                />
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    swiper.slideTo(index, 1000);
  }, [index]);

  const handleEnd = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    if (index >= swiper.slides.length - 1) {
      setIndex(0);
    } else {
      setIndex(i => i + 1);
    }
  };

  const videoConfig = (video, i) => {
    return (
      <ReactPlayer
        loop={_isEmpty(deviceSpecificBanners)}
        onEnded={handleEnd}
        playsinline={true}
        fullscreen={false}
        previewTabIndex={1}
        autoPlay={true}
        url={video}
        muted={true}
        playing={i === index && autoplay}
        width={'100vw'}
        height={aspectratio ? `calc( 100vw / ${aspectratio})` : 'initial'}
        controls={false}
        config={{
          file: {
            attributes: {
              autoplay: true,
              muted: true,
            },
          },
        }}
      />
    );
  };

  const videoSwiper = (
    <Swiper
      initialSlide={0}
      spaceBetween={0}
      effect={'fade'}
      modules={[EffectFade]}
      centeredSlides={true}
      className="rotate-banner--wrapper"
      onBeforeInit={swiper => {
        swiperRef.current = swiper;
      }}
      allowTouchMove={false}
      // onSlideChange={swiper => {
      //   setIndex(swiper.activeIndex);
      // }}
    >
      <SwiperSlide>
        {videoConfig(webUtils.isMobile() ? mobileVideo : desktopVideo, 0)}
      </SwiperSlide>
      {!_isEmpty(deviceSpecificBanners) &&
        deviceSpecificBanners.map((s, i) => (
          <SwiperSlide>
            {videoConfig(_get(s, 'bannerVideo.data.attributes.url'), i + 1)}
          </SwiperSlide>
        ))}
    </Swiper>
  );

  const sliderBar = () => {
    if (_isEmpty(deviceSpecificBanners) || deviceSpecificBanners.length < 1)
      return;

    return (
      <div className="js-slider-hero__block slide--bar">
        {[...deviceSpecificBanners, {id: 'default'}].map((s, i) => (
          <div
            onClick={() => {
              setIndex(i);
              swiperRef.current.slideTo(i, 800);
            }}
            className="js-slider-hero__bullet-area"
            key={s.id || i}
          >
            <div
              className="js-slider-hero__bullet"
              style={{
                width: 50,
                backgroundColor: index === i ? '#ef3f78' : 'rgba(0, 0, 0, 0.1)',
              }}
            />
          </div>
        ))}
        <div className="pf-rotate-banner__autoplay-btn">
          <img
            alt=""
            onClick={() => setAutoplay(!autoplay)}
            src={
              autoplay
                ? require('../../../assets/images/new_banner/jewelry/icon_pause_dark.svg')
                : require('../../../assets/images/new_banner/jewelry/icon_play_dark.svg')
            }
          />
        </div>
      </div>
    );
  };

  if (!desktopVideo && !mobileVideo && !desktopPoster && !mobilePoster)
    return <div />;

  return (
    <div style={{position: 'relative', backgroundColor: '#fff'}}>
      <VisibilityObserver onChangeVisibility={handleChangeVisibility}>
        <div>
          <div
            className={`pf-product__video-banner--container ${version} ${bgClassName}`}
            style={{
              backgroundImage:
                !webUtils.isMobile() &&
                desktopPoster &&
                _isEmpty(deviceSpecificBanners)
                  ? `url(${desktopPoster})`
                  : !bgClassName && 'initial',
              height:
                !webUtils.isMobile() &&
                aspectratio &&
                `calc( 100vw / ${aspectratio})`,
            }}
          >
            {isSmb && functionType !== 'SKINCARE_PRO' && (
              <div
                onClick={() => {
                  if (functionType === 'EYEWEAR') {
                    window.location.href = pathUtils.addLangPrefix(
                      '/business/showcase/online-service/eye-wear'
                    );
                  } else if (functionType === 'SKINCARE_PRO') {
                    window.location.href = pathUtils.addLangPrefix(
                      '/business/showcase/online-service/skincare-pro'
                    );
                  } else {
                    window.location.href = pathUtils.addLangPrefix(
                      '/business/showcase/online-service'
                    );
                  }
                }}
                className={classnames({
                  'opacity-btn': true,
                  'opacity-btn-eyewear': functionType === 'EYEWEAR',
                })}
              />
            )}
            {!webUtils.isMobile() && videoSwiper}
            {!webUtils.isMobile() && sliderBar()}
            {webUtils.isMobile() && (
              <div
                className={`pf-product__video-mobile--container ${bgClassName} ${version}`}
                style={{
                  backgroundImage: mobilePoster
                    ? `url('${mobilePoster}')`
                    : 'initial',
                  height: aspectratio
                    ? `calc( 100vw / ${aspectratio})`
                    : 'initial',
                }}
              >
                {videoSwiper}
              </div>
            )}
            {webUtils.isMobile() && sliderBar()}
            <div className="pf-product__video-banner--content">
              {_get(superscript, 'title') && (
                <div className="pf-product__banner--superscript">
                  <div>{_get(superscript, 'title')}</div>
                  <img alt="" src={_get(superscript, 'icon')} />
                </div>
              )}
              <h1 className="pf-product__banner--title">
                {title}
                {enhancedTitle && (
                  <span className="pf-product__banner--enhancedTitle">
                    {enhancedTitle}
                  </span>
                )}
              </h1>
              <div className="pf-product__banner--desc">{desc}</div>
              {(width > 1024 || webUtils.isMobile()) && buttonsCollections()}
            </div>
          </div>
          {!webUtils.isMobile() && width <= 1024 && buttonsCollections()}
          {webUtils.isMobile() && (
            <div className="pf-product__banner--divider" />
          )}
        </div>
      </VisibilityObserver>
      {isOnlineService && (
        <OnlineServiceSwitchBtn
          pageType={pageType}
          setPageType={setPageType}
          width={width}
        />
      )}
    </div>
  );
}
