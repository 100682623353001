import React, {useEffect, useState} from 'react';
import LazyLoad from 'react-lazyload';
import Benefit from './component/benefit/async';
import Parser from 'react-html-parser';
import RichEditor from './component/richeditor/async';
import _isEmpty from 'lodash/isEmpty';
import './index.css';
import strapiHtmlParserUtils from './strapiHtmlParserUtils';
import FeatureCarouse from './component/featurecarousel/async';
import ApplicationLink from './component/applicationlink/async';
import StoryLink from '../content/storylink1/async';
import webUtils from '../../utils/webUtils';
import SectionTryOn from './component/sectiontryon/async';
import FullImageBottom from './component/fullImagebottom/async';
import OneAppLinks from './component/oneapplinks/async';
import ProductVideoBanner from '../productpagelayout/videobanner';
import _get from 'lodash/get';
import Container from '../layout/container';
import SectionSteps from './component/sectionsteps/async';
import VideoStep from './component/videostep/async';
import Story from '../../pages/business/home/story/async';
import SectionIconTitle from './component/sectionicontitle/async';
import SkinChoose from './component/skinchoose/async';
import SectionAccordion from './component/sectionaccordion/async';
import Comparison from './component/skincomparison/async';
import SectionTable from './component/sectiontable/async';
import ExtraButton from '../productpagelayout/extrabutton';
import SectionShowcase from './component/sectionshowcase/async';
import SectionMulti from './component/sectionmulti/async';
import SectionBlock from './component/sectionblock/async';
import SlidesShow from './component/slideshow/async';
import MidBanner from './component/midbanner/async';
import FullVideoSection from './component/fullvideosection/async';
import SectionMultiTry from './component/sectionmultitry/async';
import BeforeAfterBanner from './component/beforeafterbanner/async';
import BeforeAfterSection from './component/beforeaftersection/async';
import SmallCardSection from './component/smallcardsection/async';
import CardSection from './component/cardsection/async';
import AppDownloadSection from './component/appdownloadsection/async';
import CompareTable from './component/comparetable/async';
import BlogCarousel from './component/blogcarousel/async';
import SectionGrids from './component/sectiongrids/async';
import PlanPricingTable from './component/planpricingtable/async';
import StrapiIframe from './component/iframe/async';
import TopicSection from './component/topicsection/async';
import SalesForm from './component/salesform/async';
import SectionTab from './component/sectiontab/async';
import SectionForm from './component/sectionform/async';
import PromoCard from './component/promocard/async';
import AppSection from './component/appsection/async';
import SectionTopBanner from './component/sectiontopbanner';
import Rate from '../apprate/async';
import SectionHalfBanner from './component/sectionhalfbanner/async';
import StrapiFaq from './component/faq/async';
import HDSDSection from './component/hdsdsection/async';
import Marquee from './component/marquee/async';
import SCFeatureIcon from './component/scfeatureicon/async';
import strapiUtils from './strapiUtils';
import SectionAppPromo from './component/sectionapppromo/async';
import BasicSection from './component/basicsection/async';
import SlideBanner from './component/slidebanner';
import SectionTerms from './component/sectionterms/async';

export default function Strapi({
  content,
  width,
  productPage,
  i18n,
  bgClassName,
  linear = '',
  babanner = false,
  setShow = () => {},
  setProductType = () => {},
  buttons = [],
  isSliding,
  supportType,
  changeUrlParam = () => {},
  sendPardot,
  setSendPardot,
  pageType = 'feature',
  setPageType = () => {},
  addBannerBorderMb = false,
}) {
  const params = new URLSearchParams(window.location.search);
  const [columns, setColumns] = useState([
    {
      id: 1,
      header: 'Perfect',
      isCrown: true,
      rows: [
        {
          id: 5,
          isCrown: false,
          isCheck: true,
          value1: 'true',
          value2: 'true',
        },
        {
          id: 18,
          isCrown: false,
          isCheck: false,
          value1: '14',
          value2: null,
        },
        {
          id: 19,
          isCrown: false,
          isCheck: false,
          value1: 'Fast',
          value2: '2sec',
        },
        {
          id: 20,
          isCrown: false,
          isCheck: false,
          value1: 'High',
          value2: null,
        },
      ],
    },
    {
      id: 3,
      header: 'Vendor R',
      isCrown: false,
      rows: [
        {
          id: 6,
          isCrown: false,
          isCheck: true,
          value1: 'true',
          value2: 'false',
        },
        {
          id: 7,
          isCrown: false,
          isCheck: false,
          value1: '5',
          value2: null,
        },
        {
          id: 8,
          isCrown: false,
          isCheck: false,
          value1: 'Slow',
          value2: '10sec',
        },
        {
          id: 13,
          isCrown: false,
          isCheck: false,
          value1: 'Low',
          value2: null,
        },
      ],
    },
    {
      id: 4,
      header: 'Vendor M',
      isCrown: false,
      rows: [
        {
          id: 9,
          isCrown: false,
          isCheck: true,
          value1: 'false',
          value2: 'true',
        },
        {
          id: 10,
          isCrown: false,
          isCheck: false,
          value1: '5',
          value2: null,
        },
        {
          id: 11,
          isCrown: false,
          isCheck: false,
          value1: 'Slow',
          value2: '10sec',
        },
        {
          id: 12,
          isCrown: false,
          isCheck: false,
          value1: 'Low',
          value2: null,
        },
      ],
    },
    {
      id: 5,
      header: 'Vendor V',
      isCrown: false,
      rows: [
        {
          id: 14,
          isCrown: false,
          isCheck: false,
          value1: 'Default',
          value2: null,
        },
        {
          id: 15,
          isCrown: false,
          isCheck: false,
          value1: '7',
          value2: null,
        },
        {
          id: 16,
          isCrown: false,
          isCheck: false,
          value1: 'Slow',
          value2: 'more than 60sec',
        },
        {
          id: 17,
          isCrown: true,
          isCheck: false,
          value1: 'Low',
          value2: null,
        },
      ],
    },
  ]);

  const [rowsTitle, setRowsTitle] = useState([
    {
      id: 2,
      isCrown: false,
      isCheck: false,
      value1: 'Front_camera',
      value2: 'Rear_camera',
    },
    {
      id: 1,
      isCrown: false,
      isCheck: false,
      value1: 'Skin Detection',
      value2: null,
    },
    {
      id: 3,
      isCrown: false,
      isCheck: false,
      value1: 'Analysis Speed',
      value2: null,
    },
    {
      id: 4,
      isCrown: false,
      isCheck: false,
      value1: 'Accuracy',
      value2: null,
    },
  ]);

  useEffect(() => {
    if (content['__component'] === 'product-page.section-table') {
      if (content.columns && content.columns.length > 0) {
        setColumns(content.columns);
      }
      if (content.rowsTitle && content.rowsTitle.length > 0) {
        setRowsTitle(content.rowsTitle);
      }
    }
  }, [content]);

  function getVideo(content) {
    if (width <= 600 && _get(content, 'videoMobile.data.attributes.url'))
      return _get(content, 'videoMobile.data.attributes.url');

    return _get(content, 'videoDesktop.data.attributes.url');
  }

  function getImg(content) {
    if (width > 600) {
      return {
        url: _get(content, 'imageDesktop.data.attributes.url'),
        alternativeText: _get(content, 'alt'),
      };
    }
    return {
      url: _get(content, 'imageMobile.data.attributes.url'),
      alternativeText: _get(content, 'alt'),
    };
  }

  function getBackgroundImage(content) {
    if (width > 600) {
      return _get(content, 'backgroundImageDesktop.data.attributes.url');
    }
    return _get(content, 'backgroundImageMobile.data.attributes.url');
  }

  function getBackgroundColor(content) {
    if (!content) return;
    if (content === 'grey') {
      return '#f2f2f2';
    }
    return content;
  }

  function getImageRatio(img) {
    if (!img || !img.width || !img.height) return 262 / 533;
    return img.height / img.width;
  }

  const convertCtaFormat = cta => {
    if (_isEmpty(cta)) return [];
    return cta.map(s => {
      return {...s, text: s.ctaText, link: s.ctaLink, newTab: s.newTab};
    });
  };

  if (content['__component'] === 'product-page.success-story-roi') {
    const item = content['showcase'].map(s => {
      return {
        title: s.conversionDescription,
        counter: {
          start: 0,
          end: s.conversionNumber,
          step: s.conversionNumber / 10,
          duration: s.conversionNumber > 100 ? 30 : 10,
          unit: s.conversionType,
        },
        logo: _get(s, 'brandLogo.data.attributes.url'),
        buttonText: s.buttonText,
        link: s.buttonLink,
      };
    });

    return (
      <LazyLoad height={300} once={true}>
        <Benefit
          width={width}
          title={Parser(_get(content, 'title'))}
          subtitle={_get(content, 'subtitle')}
          bottomDesc={Parser(_get(content, 'description'))}
          bottomText={_get(content, 'exploreStories.ctaText')}
          bottomLink={_get(content, 'exploreStories.ctaLink')}
          bottomCtaNewTab={_get(content, 'exploreStories.newTab')}
          items={item}
        />
      </LazyLoad>
    );
  }

  if (content['__component'] === 'product-page.rich-editor') {
    const getImageData = () => {
      const tmp =
        width > 600
          ? _get(content, 'sectionImageDesktop')
          : _get(content, 'sectionImageMobile');
      return {
        link: _get(tmp, 'link'),
        alt: _get(tmp, 'alt'),
        src:
          width > 600
            ? _get(tmp, 'image.data.attributes.url')
            : _get(tmp, 'image.data.attributes.url'),
      };
    };
    return (
      <RichEditor
        width={width}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'))}
        icon={_get(content.icon, 'data.attributes.url')}
        image={getImageData()}
        layout={_get(content, 'Layout', 'Standard')}
      />
    );
  }
  if (content['__component'] === 'product-page.basic-section') {
    return (
      <BasicSection
        isCrossSection={content.isCrossSection && width > 600}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        ratio={
          width > 600
            ? getImageRatio(
                _get(content, 'sectionMedia.imageDesktop.data.attributes')
              )
            : getImageRatio(
                _get(content, 'sectionMedia.imageMobile.data.attributes')
              )
        }
        reverse={_get(content, 'textPosition') === 'right'}
        title={_get(content, 'sectionText.title')}
        desc={Parser(_get(content, 'sectionText.description'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        videoUrl={getVideo(_get(content, 'sectionMedia'))}
        image={getImg(_get(content, 'sectionMedia'))}
        cta={convertCtaFormat(content.cta)}
        titleIcon={_get(content, 'sectionText.titleIcon.data.attributes.url')}
        superscriptTitle={_get(content, 'sectionText.superscriptTitle')}
        layout={_get(content, 'layout')}
      />
    );
  }
  if (content['__component'] === 'product-page.feature-carousel') {
    const products = content['features'].map(s => {
      return {
        title: s.featureTitle,
        ctaText: s.ctaText,
        ctaLink: s.ctaLink,
        desc: s.description,
        video: _get(s, 'video.data.attributes.url'),
        poster: _get(s, 'image.data.attributes.url'),
        contentSubtitle: _get(s, 'contentSubtitle'),
      };
    });
    return (
      <FeatureCarouse
        products={products}
        width={width}
        background={getBackgroundImage(content)}
        title={Parser(content.title)}
        desc={Parser(content.subtitle)}
        defaultPoster={_get(content, 'defaultPoster.data.attributes.url')}
        carouselTime={_get(content, 'carouselTime')}
      />
    );
  }

  if (content['__component'] === 'product-page.section-grids') {
    return (
      <SectionGrids
        width={width}
        title={content.title}
        backgroundColor={getBackgroundColor(
          _get(content, 'background.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
        button={_get(content, 'button')}
        grids={_get(content, 'grids')}
      />
    );
  }

  if (content['__component'] === 'product-page.application') {
    let items = [];
    if (!_isEmpty(content['apiServiceList'])) {
      items.push({
        item: content['apiServiceList'],
        icon: _get(content, 'apiIcon.data.attributes.url'),
      });
    }
    if (!_isEmpty(content['instoreServiceList'])) {
      items.push({
        item: content['instoreServiceList'],
        icon: _get(content, 'instoreIcon.data.attributes.url'),
      });
    }
    if (!_isEmpty(content['sdkServiceList'])) {
      items.push({
        item: content['sdkServiceList'],
        icon: _get(content, 'sdkIcon.data.attributes.url'),
      });
    }

    if (!_isEmpty(items)) {
      if (items.length > 1) {
        return (
          <ApplicationLink
            items={items}
            title={content.title}
            desc={content.description}
            width={width}
          />
        );
      }
      return (
        <OneAppLinks
          width={width}
          item={items[0]}
          title={content.title}
          desc={content.description}
        />
      );
    }
  }
  if (content['__component'] === 'product-page.bottom-cta') {
    return (
      <StoryLink
        title={content.title}
        desc={content.description}
        width={width}
        buttonConfig={[
          {
            text: _get(content, 'buttonLeft.buttonTitle'),
            link: _get(content, 'buttonLeft.buttonLink'),
            bgColor: 'pink',
            gtm: `gtm_products_contact-sales_${productPage}_bottom_${webUtils.determineLanguage(
              i18n.language
            )}`,
            newTab: _get(content, 'buttonLeft.newTab'),
          },
          {
            text: _get(content, 'buttonRight.buttonTitle'),
            link: _get(content, 'buttonRight.buttonLink'),
            bgColor: 'white',
            newTab: _get(content, 'buttonRight.newTab'),
          },
        ]}
      />
    );
  }
  if (content['__component'] === 'product-page.section-try-on') {
    return (
      <SectionTryOn
        backgroundColor={_get(content, 'sectionBackground.backgroundColor')}
        background={getBackgroundImage(_get(content, 'sectionBackground'))}
        backgroundImage={getImg(content['sectionImage'])}
        icon={_get(content['sectionImage'], 'iconForTryOn.data.attributes.url')}
        imageTitle={_get(content, 'sectionImage.titleOfProduct')}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'))}
        buttonConfig={{
          text: content.tryOnButton.buttonTitle,
          link: content.tryOnButton.buttonLink,
          newTab: content.tryOnButton.newTab,
        }}
        contentCta={convertCtaFormat(content.cta)}
      />
    );
  }
  if (content['__component'] === 'product-page.section-full-image') {
    return (
      <FullImageBottom
        cta={content['cta']}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        image={content['sectionImage'] && getImg(content['sectionImage'])}
        reverse={_get(content, 'textPosition') === 'right'}
        width={width}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        videoUrl={getVideo(_get(content, 'sectionImage'))}
      />
    );
  }
  if (content['__component'] === 'shared.borderline') {
    return (
      <Container
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: content.lineColor,
        }}
      />
    );
  }
  if (content['__component'] === 'product-page.section-steps') {
    return (
      <SectionSteps
        title={Parser(content.title)}
        description={Parser(content.description)}
        blocks={content.blocks}
        cta={content.cta}
        orientationOnMobile={content.orientationOnMobile}
        width={width}
        isSteps={content.isSteps}
        stepText={content.stepText}
        stepNumPosition={content.stepNumPosition}
      />
    );
  }
  if (content['__component'] === 'product-page.section-video-step') {
    return (
      <VideoStep
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        video={content.video}
        videoSteps={content.videoSteps}
        width={width}
        title={_get(content, 'title')}
      />
    );
  }
  if (content['__component'] === 'product-page.success-story-carousel') {
    return (
      <Story
        gtmClassNamePrefix={'gtm_enterprise_story'}
        bgc={width <= 800 && '#ffffff'}
        itemId={content.itemId}
        buttonText={content['cta'].ctaText}
        buttonLink={content['cta'].ctaLink}
        isNewTab={content['cta'].newTab}
        title={Parser(content.title)}
        subTitle={Parser(content.description)}
      />
    );
  }
  if (content['__component'] === 'product-page.section-icon-title') {
    return (
      <SectionIconTitle
        title={_get(content, 'title')}
        icon={_get(content.icon, 'data.attributes.url')}
      />
    );
  }
  if (content['__component'] === 'product-page.top-banner' && !babanner) {
    const dt_aspectratio =
      _get(content, 'backgroundDesktop.data.attributes.width') /
      _get(content, 'backgroundDesktop.data.attributes.height');

    const m_aspectratio =
      _get(content, 'backgroundMobile.data.attributes.width') /
      _get(content, 'backgroundMobile.data.attributes.height');

    return (
      <ProductVideoBanner
        buttons={buttons}
        setShow={setShow}
        aspectratio={width > 600 ? dt_aspectratio : m_aspectratio}
        title={Parser(content.title)}
        desc={Parser(content.description)}
        buttonConfig={[
          {
            text: _get(content, 'buttonLeft.buttonTitle'),
            link:
              !_get(content, 'buttonLeft.isPlayVideo') &&
              _get(content, 'buttonLeft.buttonLink'),
            videoUrl:
              _get(content, 'buttonLeft.isPlayVideo') &&
              _get(content, 'buttonLeft.buttonLink'),
            style: _get(content, 'buttonLeft.isPlayVideo') ? 'video' : 'pink',
            gtm: `gtm_products_contact-sales_${productPage}`,
            newTab: _get(content, 'buttonLeft.newTab'),
            buttonType: _get(content, 'buttonLeft.buttonType'),
          },
          {
            text: _get(content, 'buttonRight.buttonTitle'),
            link:
              !_get(content, 'buttonRight.isPlayVideo') &&
              _get(content, 'buttonRight.buttonLink'),
            videoUrl:
              _get(content, 'buttonRight.isPlayVideo') &&
              _get(content, 'buttonRight.buttonLink'),
            style: _get(content, 'buttonRight.isPlayVideo') ? 'video' : 'white',
            newTab: _get(content, 'buttonRight.newTab'),
            gtm: `gtm_products_try-demo_${productPage}`,
            buttonType: _get(content, 'buttonRight.buttonType'),
          },
        ]}
        videoStyle={{width: '100%', height: width >= 1366 && '100%'}}
        desktopVideo={_get(content, 'bannerDesktop.data.attributes.url')}
        mobileVideo={_get(content, 'bannerMobile.data.attributes.url')}
        desktopPoster={_get(content, 'backgroundDesktop.data.attributes.url')}
        mobilePoster={_get(content, 'backgroundMobile.data.attributes.url')}
        gtmClassNamePrefix={`gtm_products_${productPage}`}
        bgClassName={bgClassName}
        extraButtons={
          (_get(content, 'iconTitle') ||
            _get(content, 'icon.data.attributes.url')) && (
            <ExtraButton
              img={_get(content, 'icon.data.attributes.url')}
              text={Parser(_get(content, 'iconTitle'))}
              link={_get(content, 'iconLink')}
            />
          )
        }
        enhancedTitle={_get(content, 'enhancedTitle')}
        ctaText={_get(content, 'ctaText')}
        superscript={{
          title: _get(content, 'superscriptTitle'),
          icon: _get(content, 'superscriptIcon.data.attributes.url'),
        }}
        setPageType={setPageType}
        pageType={pageType}
        rotateBanner={_get(content, 'multiDpiBanner')}
      />
    );
  }
  if (content['__component'] === 'product-page.section-item-choose') {
    return (
      <SkinChoose
        title={Parser(content.title)}
        desc={Parser(content.description)}
        width={width}
        gtmClassNamePrefix="gtm_products_skindiagnostic"
        cta={content.cta}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
        backgroundColor={getBackgroundColor(
          _get(content, 'background.backgroundColor')
        )}
      />
    );
  }
  if (content['__component'] === 'product-page.section-accordion') {
    if (_isEmpty(content.accordionItems)) return;
    const configs = content.accordionItems.map(s => {
      return {
        title: s.title,
        desc: Parser(s.description),
        icon: _get(s, 'icon.data.attributes.url'),
        img: _get(s, 'image.data.attributes.url'),
        video: _get(s, 'video.data.attributes.url'),
        button: {text: s.ctaText, link: s.ctaLink},
        alt: s.alt,
      };
    });

    return (
      <SectionAccordion
        title={content.title}
        bgc={getBackgroundColor(_get(content, 'background'))}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
        width={width}
        configs={configs}
      />
    );
  }
  if (content['__component'] === 'product-page.section-table') {
    return (
      <SectionTable
        width={width}
        title={content.title}
        columns={columns}
        rowsTitle={rowsTitle}
      />
    );
  }

  if (content['__component'] === 'product-page.compare-table') {
    return (
      <CompareTable
        width={width}
        title={Parser(_get(content, 'title'))}
        button={_get(content, 'button')}
        rows={_get(content, 'rows')}
        columnsTitle={_get(content, 'columnsTitle')}
      />
    );
  }

  if (content['__component'] === 'product-page.section-showcase') {
    return (
      <SectionShowcase
        title={Parser(content.title)}
        desc={Parser(content.description)}
        story={{
          img: _get(content, 'showcase.brandLogo.data.attributes.url'),
          link: _get(content, 'showcase.buttonLink'),
          buttonText: _get(content, 'showcase.buttonText'),
          conversionBottomDescription: _get(
            content,
            'showcase.conversionBottomDescription'
          ),
          conversionDescription: _get(
            content,
            'showcase.conversionDescription'
          ),
          conversionNumber: _get(content, 'showcase.conversionNumber'),
          conversionType: _get(content, 'showcase.conversionType'),
        }}
      />
    );
  }

  if (content['__component'] === 'product-page.section-multi-img') {
    return (
      <SectionMulti
        cta={content['cta']}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        images={content['sectionImages']}
        reverse={_get(content, 'textPosition') === 'right'}
        width={width}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
      />
    );
  }

  if (content['__component'] === 'product-page.section-blocks') {
    return (
      <SectionBlock blocks={[content.block1, content.block2, content.block3]} />
    );
  }
  if (content['__component'] === 'product-page.section-slideshow') {
    const config = content.sectionSlideshow.map(s => {
      return {
        text: s.title,
        image: _get(s, 'slideImage.data.attributes.url'),
        alt: s.alt,
        video: _get(s, 'slideVideo.data.attributes.url'),
        superscriptTitle: _get(s, 'superscriptTitle'),
      };
    });
    return (
      <SlidesShow
        width={width}
        Configs={config}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'))}
        button={{
          text: _get(content, 'cta[0].ctaText'),
          link: _get(content, 'cta[0].ctaLink'),
          newTab: _get(content, 'cta[0].newTab'),
        }}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        videoLength={_get(content, 'videoLength')}
      />
    );
  }
  if (content['__component'] === 'product-page.mid-banner') {
    const aspectratio =
      width > 1024
        ? _get(content, 'backgroundDesktop.data.attributes.width') /
          _get(content, 'backgroundDesktop.data.attributes.height')
        : _get(content, 'backgroundMobile.data.attributes.width') /
          _get(content, 'backgroundMobile.data.attributes.height');
    return (
      <MidBanner
        width={width}
        aspectratio={aspectratio}
        banner={
          width > 1024
            ? _get(content, 'bannerDesktop.data.attributes.url')
            : _get(content, 'bannerMobile.data.attributes.url')
        }
        backgroundImage={
          width > 1024
            ? _get(content, 'backgroundDesktop.data.attributes.url')
            : _get(content, 'backgroundMobile.data.attributes.url')
        }
        button={_get(content, 'button')}
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        isDropdownlist={_get(content, 'IsDropdownButtons')}
        dropdownlist={_get(content, 'dropdownButtons')}
      />
    );
  }
  if (content['__component'] === 'product-page.section-full-video') {
    const aspectRatio =
      width > 600
        ? _get(content, 'sectionVideo.imageDesktop.data.attributes.height') /
          _get(content, 'sectionVideo.imageDesktop.data.attributes.width')
        : _get(content, 'sectionVideo.imageMobile.data.attributes.height') /
          _get(content, 'sectionVideo.imageMobile.data.attributes.width');

    return (
      <FullVideoSection
        aspectRatio={aspectRatio}
        cta={content['cta']}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        image={getImg(_get(content, 'sectionVideo'))}
        width={width}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        videoUrl={getVideo(_get(content, 'sectionVideo'))}
      />
    );
  }

  if (content['__component'] === 'product-page.section-multi-try') {
    if (_get(content, 'tryOnButton1')) {
      content['tryOnButton1']['title'] = Parser(
        content['tryOnButton1']['titleOfProduct']
      );
      content['tryOnButton1']['icon'] = _get(
        content,
        'tryOnButton1.tryOnIcon.data.attributes.url'
      );
    }
    if (_get(content, 'tryOnButton2')) {
      content['tryOnButton2']['title'] = Parser(
        content['tryOnButton2']['titleOfProduct']
      );
      content['tryOnButton2']['icon'] = _get(
        content,
        'tryOnButton2.tryOnIcon.data.attributes.url'
      );
    }
    const dt_aspectratio =
      _get(content, 'sectionImage.imageDesktop.data.attributes.width') /
      _get(content, 'sectionImage.imageDesktop.data.attributes.height');

    const m_aspectratio =
      _get(content, 'sectionImage.imageMobile.data.attributes.width') /
      _get(content, 'sectionImage.imageMobile.data.attributes.height');

    return (
      <SectionMultiTry
        width={width}
        aspectratio={width > 600 ? dt_aspectratio : m_aspectratio}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'))}
        tryOnButton1={_get(content, 'tryOnButton2')}
        tryOnButton2={_get(content, 'tryOnButton1')}
        sectionImage={getImg(_get(content, 'sectionImage'))}
        dtVideoUrl={_get(
          content,
          'sectionImage.videoDesktop.data.attributes.url'
        )}
        mbVideoUrl={
          _get(content, 'sectionImage.videoMobile.data.attributes.url') ||
          _get(content, 'sectionImage.videoDesktop.data.attributes.url')
        }
      />
    );
  }

  if (content['__component'] === 'topBanner' && babanner) {
    const aspectratio = () => {
      if (!_isEmpty(_get(content, 'imageBeAf'))) {
        return width > 800
          ? _get(
              content.imageBeAf[0],
              'imageBeforeDesktop.data.attributes.width'
            ) /
              _get(
                content.imageBeAf[0],
                'imageBeforeDesktop.data.attributes.height'
              )
          : _get(
              content.imageBeAf[0],
              'imageBeforeMobile.data.attributes.width'
            ) /
              _get(
                content.imageBeAf[0],
                'imageBeforeMobile.data.attributes.height'
              );
      }

      return 2732 / 1652;
    };

    return (
      <BeforeAfterBanner
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        logo={_get(content, 'appLogo.data.attributes.url')}
        imageBeAf={_get(content, 'imageBeAf')}
        width={width}
        aspectratio={aspectratio()}
        imageCTA={_get(content, 'imageCTA')}
      />
    );
  }

  if (
    content['__component'] === 'apps-page.section-image-be-af' ||
    content['__component'] === 'apps-page.section-be-af-image'
  ) {
    return (
      <BeforeAfterSection
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        reverse={_get(content, 'textPosition') !== 'left'}
        linear={linear}
        title={Parser(_get(content, 'sectionText.title'))}
        desc={Parser(_get(content, 'sectionText.description'))}
        sectionOrderLeft={_get(content, 'sectionOrderLeft')}
        sectionOrderRight={_get(content, 'sectionOrderRight')}
        sectionMedia={
          _get(content, 'sectionMedia') || _get(content, 'sectionBeAfMedia')
        }
        cta={_get(content, 'cta')}
        type={_get(content, 'layout')}
      />
    );
  }

  if (content['__component'] === 'apps-page.section-icon-text') {
    return (
      <SmallCardSection
        backgroundColor={getBackgroundColor(
          _get(content, 'background.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'backgroundColor'))}
        features={_get(content, 'features')}
      />
    );
  }

  if (content['__component'] === 'apps-page.section-cards') {
    const cards = () => {
      if (_isEmpty(_get(content, 'cards'))) return;
      return _get(content, 'cards').map(s => {
        return {
          image: getImg(s),
          desc: Parser(s.description),
          title: Parser(s.subtitle),
          tryOnButton: {
            text: s.ctaText,
            link: s.ctaLink,
            newTab: s.newTab,
            nofollow: s.nofollow,
          },
        };
      });
    };
    return (
      <CardSection
        linear={linear}
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        enhanceText={Parser(_get(content, 'enhancedTitle'))}
        cards={cards()}
      />
    );
  }

  if (content['__component'] === 'apps-page.section-bottom') {
    return (
      <AppDownloadSection
        linear={linear}
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        imageCTA={_get(content, 'imageCTA')}
        logo={_get(content, 'logo.data.attributes.url')}
      />
    );
  }

  if (content['__component'] === 'product-page.blog-carousel') {
    return (
      <BlogCarousel
        title={Parser(content.title)}
        itemId={content.itemId}
        width={width}
        i18n={i18n}
        itemLink={content.itemLink}
        cta={_get(content, 'cta')}
      />
    );
  }

  if (content['__component'] === 'product-page.plan-pricing') {
    return (
      <PlanPricingTable
        backgroundColor={getBackgroundColor(_get(content, 'backgroundColor'))}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
        title={Parser(content.title)}
        width={width}
        essentialDesc={Parser(_get(content, 'content.essential'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        premiumDesc={Parser(_get(content, 'content.premium'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        enterpriseDesc={Parser(_get(content, 'content.enterprise'), {
          transform: (node, index) => {
            strapiHtmlParserUtils.transformDescContent(node, index);
          },
        })}
        cta={_get(content, 'cta')}
      />
    );
  }

  if (content['__component'] === 'shared.iframe') {
    return (
      <StrapiIframe
        sourceCode={_get(content, 'sourceCode')}
        title={_get(content, 'title')}
        desc={Parser(_get(content, 'description'))}
      />
    );
  }

  if (content['__component'] === 'product-page.section-multi-cards') {
    const cards = _get(content, 'sections').map(s => {
      return {
        video: getVideo(s),
        title: s.title,
        desc: Parser(s.description),
        size: s.width,
        reverse: s.textPosition === 'left',
        img: getImg(s),
        cta: _get(s, 'cta'),
      };
    });
    return (
      <TopicSection
        card={cards}
        setProductType={setProductType}
        backgroundColor={getBackgroundColor(
          _get(content, 'backgroundColorType')
        )}
        width={width}
        title={_get(content, 'title')}
        desc={Parser(_get(content, 'description'))}
        topImage={_get(content, 'backgroundDesktop1.data.attributes.url')}
        bottomImage={_get(content, 'backgroundDesktop2.data.attributes.url')}
        footerBanner={{
          cta: {
            text: _get(content, 'midBanner.button.buttonTitle'),
            link: _get(content, 'midBanner.button.buttonLink'),
            newTab: _get(content, 'midBanner.button.newTab'),
          },
          title: Parser(_get(content, 'midBanner.title')),
          desc: Parser(_get(content, 'midBanner.description')),
          background:
            width > 1024
              ? _get(content, 'midBanner.bannerDesktop.data.attributes.url')
              : _get(content, 'midBanner.bannerMobile.data.attributes.url'),
        }}
        isSliding={isSliding}
      />
    );
  }

  if (content['__component'] === 'shared.section-contact-sale') {
    return (
      <SalesForm
        title={_get(content, 'title')}
        desc={Parser(_get(content, 'subtitle'))}
        backgroundColor={getBackgroundColor(
          _get(content, 'background.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
        supportType={supportType}
      />
    );
  }

  if (content['__component'] === 'product-page.section-tab') {
    return (
      <SectionTab
        backgroundColor={getBackgroundColor(
          _get(content, 'background.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
        title={_get(content, 'title')}
        desc={Parser(_get(content, 'subtitle'))}
        tab={_get(content, 'tab')}
        width={width}
      />
    );
  }

  if (content['__component'] === 'product-page.section-form') {
    const type = params.get('type') || 'a';
    const section = [
      _get(content, 'section')[
        type === 'b' && _get(content, 'section').length > 2 ? 2 : 0
      ],
      _get(content, 'section')[1],
    ].filter(s => s);

    return (
      <SectionForm
        section={section}
        width={width}
        changeUrlParam={changeUrlParam}
        sendPardot={sendPardot}
        setSendPardot={setSendPardot}
        i18n={i18n}
        type={type}
      />
    );
  }

  if (content['__component'] === 'product-page.section-promo-card') {
    const card = _get(content, 'cards').map(s => {
      return {
        ...s,
        img: getImg(s).url,
        video: getVideo(s),
        alt:
          getImg(s).alternativeText || strapiUtils.strapiHTMLTags(s.subtitle),
        description: Parser(s.description),
        titleIcon: _get(s, 'titleIcon.data.attributes.url'),
      };
    });
    return (
      <PromoCard
        width={width}
        title={Parser(_get(content, 'title'))}
        description={Parser(_get(content, 'description'))}
        cards={card}
        backgroundColor={getBackgroundColor(
          _get(content, 'background.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
      />
    );
  }
  if (content['__component'] === 'apps-page.section-app-feature') {
    return (
      <AppSection
        id={_get(content, 'anchorTag')}
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        sectionSlides={_get(content, 'sectionSlides')}
        width={width}
        isReversed={_get(content, 'textPosition') === 'right'}
      />
    );
  }
  if (content['__component'] === 'apps-page.section-top-banner') {
    const appList = () => {
      if (_isEmpty(_get(content, 'iconButtons'))) return [];
      return _get(content, 'iconButtons').map(s => {
        return {
          name: s.instruction,
          icon: _get(s, 'Image.data.attributes.url'),
          text: s.instruction,
          link: s.ctaLink,
          newTab: s.newTab,
        };
      });
    };
    const dt_aspectratio =
      _get(content, 'backgroundDesktop.data.attributes.width') /
      _get(content, 'backgroundDesktop.data.attributes.height');

    const m_aspectratio =
      _get(content, 'backgroundMobile.data.attributes.width') /
      _get(content, 'backgroundMobile.data.attributes.height');

    const icon_aspectRatio =
      _get(content, 'appLogo.data.attributes.width') /
      _get(content, 'appLogo.data.attributes.height');

    const isDevice = width <= 1024;
    if (_get(content, 'layoutType') === 'Half') {
      return (
        <SectionHalfBanner
          isDevice={isDevice}
          title={Parser(_get(content, 'title'))}
          description={Parser(_get(content, 'description'))}
          titleIcon={_get(content, 'appLogo.data.attributes.url')}
          imageCTA={_get(content, 'imageCTA', [])}
          video={
            width > 1024
              ? _get(content, 'bannerDesktop.data.attributes.url')
              : _get(content, 'bannerMobile.data.attributes.url')
          }
          poster={
            width > 1024
              ? _get(content, 'backgroundDesktop.data.attributes.url')
              : _get(content, 'backgroundMobile.data.attributes.url')
          }
          width={width}
          aspectratio={width > 1024 ? dt_aspectratio : m_aspectratio}
        />
      );
    }

    return (
      <SectionTopBanner
        isDevice={isDevice}
        iconIsSquare={icon_aspectRatio === 1}
        title={Parser(_get(content, 'title'))}
        content={Parser(_get(content, 'description'))}
        titleIcon={_get(content, 'appLogo.data.attributes.url')}
        imageCTA={_get(content, 'imageCTA', [])}
        video={
          width > 600
            ? _get(content, 'bannerDesktop.data.attributes.url')
            : _get(content, 'bannerMobile.data.attributes.url')
        }
        poster={
          width > 600
            ? _get(content, 'backgroundDesktop.data.attributes.url')
            : _get(content, 'backgroundMobile.data.attributes.url')
        }
        width={width}
        aspectratio={width > 600 ? dt_aspectratio : m_aspectratio}
        appList={appList()}
        addBannerBorderMb={addBannerBorderMb}
      />
    );
  }

  if (content['__component'] === 'apps-page.section-app-rating') {
    const comments = () => {
      if (_isEmpty(_get(content, 'sectionBlocks'))) return [];
      return _get(content, 'sectionBlocks').map(s => {
        return {
          name: Parser(_get(s, 'subtitle')),
          text: Parser(_get(s, 'description')),
          backgroundColor: _get(s, 'backgroundColor'),
          outlineColor: _get(s, 'outlineColor'),
          image: _get(s, 'Image.data.attributes.url'),
        };
      });
    };

    return (
      <Rate
        backgroundColor={getBackgroundColor(
          _get(content, 'sectionBackground.backgroundColor')
        )}
        backgroundImage={getBackgroundImage(_get(content, 'sectionBackground'))}
        title={Parser(_get(content, 'title'))}
        date={Parser(_get(content, 'subtitle'))}
        comments={comments()}
        scores={[
          _get(content, 'appleStoreRating'),
          _get(content, 'googlePlayRating'),
        ]}
      />
    );
  }
  if (content['__component'] === 'apps-page.section-faq') {
    return (
      <StrapiFaq
        title={Parser(_get(content, 'title'))}
        faqList={_get(content, 'faqList')}
        collapseIcon={_get(content, 'collapseIcon.data.attributes.url')}
        expandIcon={_get(content, 'expandIcon.data.attributes.url')}
      />
    );
  }
  if (content['__component'] === 'product-page.section-compare') {
    return (
      <HDSDSection
        i18n={i18n}
        width={width}
        title={Parser(_get(content, 'title'))}
        cta={_get(content, 'cta')}
        desc={Parser(_get(content, 'description'))}
        hd={{
          ..._get(content, 'hd'),
          subtitle: Parser(_get(content, 'hd.subtitle')),
          desc: Parser(_get(content, 'hd.description')),
          type: 'HD',
        }}
        sd={{
          ..._get(content, 'sd'),
          subtitle: Parser(_get(content, 'sd.subtitle')),
          desc: Parser(_get(content, 'sd.description')),
          type: 'SD',
        }}
      />
    );
  }
  if (content['__component'] === 'react-fast-marquee') {
    return (
      <Marquee
        content={
          <img
            height={webUtils.isMobile() ? 90 : 76}
            alt=""
            src={
              'https://plugins-media.makeupar.com/smb/story/2024-07-09/2536c841-8c9b-46b6-99ee-3c5f5af0c8dd.jpg'
            }
          />
        }
      />
    );
  }
  if (content['__component'] === 'product-page.sc-feature-icon') {
    return (
      <SCFeatureIcon
        width={width}
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        backgroundColor={getBackgroundColor(_get(content, 'backgroundColor'))}
        backgroundImage={getBackgroundImage(_get(content, 'backgroundColor'))}
        sectionFeature={_get(content, 'sectionFeature')}
      />
    );
  }
  if (content['__component'] === 'product-page.section-app-promo') {
    return (
      <SectionAppPromo
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        cards={_get(content, 'cards')}
        backgroundColor={getBackgroundColor(_get(content, 'background'))}
        backgroundImage={getBackgroundImage(_get(content, 'background'))}
      />
    );
  }
  if (content['__component'] === 'product-page.slide-banner') {
    return <SlideBanner data={_get(content, 'banners')} width={width} />;
  }

  if (content['__component'] === 'shared.section-terms') {
    return (
      <SectionTerms
        title={Parser(_get(content, 'title'))}
        desc={Parser(_get(content, 'description'))}
        subtitle={_get(content, 'subtitle')}
      />
    );
  }
  return <div />;
}
