import React from 'react';
import classnames from 'classnames';
import Container from '../../../layout/container';
import webUtils from '../../../../utils/webUtils';
import './appvideobanner.css';
import AppIconList from '../../../../pages/consumer/home/AppIconList';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ButtonType from '../../buttonType';
import PFLink from '../../../link';
import '../sectionhalfbanner/index.css';
import _isNull from 'lodash/isNull';
import '../../../../pages/consumer/home/index.css';

export default function SectionTopBanner({
  title,
  titleIcon,
  content,
  video = '',
  width,
  poster,
  aspectratio = 820 / 420,
  iconIsSquare = 1,
  appList = [],
  addBannerBorderMb,
  imageCTA,
  isDevice,
}) {
  const isAndroid = webUtils.isAndroid();
  const downloadLinks = isDevice
    ? isAndroid
      ? imageCTA.filter(s => s.os === 'Android' || _isNull(s.os))
      : imageCTA.filter(s => s.os === 'iOS' || _isNull(s.os))
    : imageCTA;

  const aspectRatioDefault = webUtils.isMobile() ? 390 / 478 : 1366 / 612;
  const bannerHeight = aspectratio
    ? `calc( 100vw / ${aspectratio})`
    : `calc( 100vw / ${aspectRatioDefault})`;
  const containerStyle = {maxWidth: 'none'};
  const getIconTitle = () => {
    return (
      <div
        className={classnames({
          'video-banner-content-title': true,
          icon: titleIcon,
          'video-banner-content-full-icon': !iconIsSquare,
        })}
      >
        {titleIcon && <img src={titleIcon} alt="" />}
        <h1 className="video-banner-content-title">{title}</h1>
      </div>
    );
  };
  const appIcon = () => {
    if (!appList || _isEmpty(appList)) return <div />;
    if (appList.length === 1) {
      return (
        <ButtonType
          type={'Pink button with icon'}
          text={_get(appList, '[0].text')}
          link={_get(appList, '[0].link')}
          icon={_get(appList, '[0].icon')}
        />
      );
    }
    return <AppIconList appList={appList} />;
  };

  const getDownloadIcon = () => {
    if (_isEmpty(downloadLinks)) return <div />;
    return downloadLinks.map(s => {
      return (
        <PFLink
          to={s.ctaLink}
          key={s.id}
          isNewTab={s.newTab}
          rel={s.nofollow ? 'nofollow noopener noreferrer' : ''}
        >
          <img alt={s.os} src={_get(s, 'Image.data.attributes.url')} />
        </PFLink>
      );
    });
  };

  return (
    <div>
      {!webUtils.isMobile() && (
        <div
          className={`video-banner consumer-home-banner`}
          style={{height: bannerHeight}}
        >
          <video
            preload={'true'}
            autoPlay
            muted
            loop
            poster={poster}
            style={{position: 'absolute', width: '100%', height: bannerHeight}}
          >
            <source src={video} />
          </video>
          <Container style={containerStyle}>
            <div className="video-banner-content">
              <div>{getIconTitle()}</div>
              {width > 1024 && (
                <div className="video-banner-content-icons">
                  {content}
                  {appIcon()}
                  <div className="section-half-banner--cta-wrapper">
                    {getDownloadIcon()}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      )}
      <div>
        {width <= 1024 && width > 600 && (
          <Container className="video-banner-content-icons">
            {content}
            <div className="video-banner-content-icons-pad"> {appIcon()}</div>
            <div className="section-half-banner--cta-wrapper">
              {getDownloadIcon()}
            </div>
          </Container>
        )}
      </div>
      {webUtils.isMobile() && (
        <Container
          className={`video-banner--mobile`}
          style={{
            borderBottom: addBannerBorderMb ? '1px solid #f2f2f2' : 'none',
          }}
        >
          <video
            preload={'true'}
            autoPlay
            loop
            muted
            playsInline
            poster={poster}
            style={{width: '100%', marginBottom: '40px', height: bannerHeight}}
          >
            <source src={video} />
          </video>
          <div className="video-banner-content">
            {getIconTitle()}
            {content}
            {appIcon()}
            <div
              className="section-half-banner--cta-wrapper text-center"
              style={{marginBottom: '30px'}}
            >
              {getDownloadIcon()}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
