import ycf from './ycf/routes';
import ycn from './ycn/routes';
import ycp from './ycp/routes';
import ycv from './ycv/routes';
import ycc from './ycc/routes';
import ymk from './ymk/routes';
//import y4b from './y4b/routes';
import yca from './yca/routes';
import dynamicapps from './dynamicapps/routes';

export default [ycf, ycn, ycp, ycc, ycv, ymk, yca, dynamicapps /*, y4b*/];
