export default [
  {
    key: 'virtual-makeup',
    value: '/business/showcase/lip-color',
  },
  {
    key: 'youcam-tutorial',
    value: '/business/showcase/youcam-tutorial',
  },
  {
    key: 'ai-shade-finder',
    value: '/business/showcase/shadefinder/home',
  },
  {
    key: 'ai-skin-diagnostic',
    value: '/business/showcase/skincare/home',
  },
  {
    key: 'ai-product-recommendation',
    value: '/business/showcase/lip-color',
  },
  {
    key: 'ai-face-analyzer',
    value: '/business/showcase/face-analyzer',
  },
  {
    key: 'in-store-barcode',
    value: '/business/showcase/lip-color',
  },
  {
    key: 'virtual-accessories',
    value: '/business/showcase/earrings',
  },
  {
    key: 'eye-wear',
    value: '/business/showcase/eye-wear',
  },
  {
    key: 'live-hair-color',
    value: '/business/showcase/hair-color',
  },
  {
    key: 'ai-beard-dye',
    value: '/business/showcase/beard-dye',
  },
  {
    key: 'ai-nail-color',
    value: '/business/showcase/nail-color',
  },
  {
    key: 'live-ar-training',
    value: '/business/showcase/lip-color',
  },
  {
    key: 'ar-virtual-ring',
    value: '/business/showcase/rings',
  },
  {
    key: 'ar-bracelet',
    value: '/business/showcase/bracelets',
  },
  {
    key: 'ar-watch',
    value: '/business/showcase/watches',
  },
  {
    key: 'ba-1-on-1',
    value: '/business/showcase/lip-color',
  },
  {
    key: 'live-streaming',
    value: '/business/showcase/lip-color',
  },
  {
    key: 'ar-virtual-earrings',
    value: '/business/showcase/earrings',
  },
  {
    key: 'face-reshaping',
    value: '/business/showcase/face-reshaping',
  },
  {
    key: 'ai-personality',
    value: '/business/showcase/personality',
  },
  {
    key: 'virtual-background',
    value: '/business/showcase/look',
  },
  {
    key: 'virtual-hairstyles',
    value: '/business/showcase/virtual-hairstyles',
  },
  {
    key: 'ai-skin-emulation',
    value: '/business/showcase/skincare/emulation',
  },
  {
    key: 'ai-virtual-necklace',
    value: '/business/showcase/necklace',
  },
  {
    key: '3d-viewer-virtual-try-on',
    value: '/business/showcase/3d-viewer/home',
  },
  {
    key: 'ar-virtual-accessories',
    value: '/business/showcase/headband',
  },
  {
    key: 'hair-type-analysis',
    value: '/business/showcase/hair-type',
  },
  {
    key: '3d-vto-format',
    value: '/business/showcase/set',
  },
  {
    key: 'hair-length-analysis',
    value: '/business/showcase/hair-length',
  },
  {
    key: 'ai-makeup-transfer',
    value: '/business/showcase/gen-ai/ai-makeup-transfer',
  },
  {
    key: 'fitzpatrick-skin-types',
    value: '/business/showcase/shadefinder/home',
  },
];
