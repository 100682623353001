import React from 'react';
import {Route} from 'react-router-dom';
import AsyncPage from './async';

export default [
  <Route
    key="/business/products/fitzpatrick-skin-types"
    exact
    path="/business/products/fitzpatrick-skin-types"
    component={AsyncPage}
  />,
];
