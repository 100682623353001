import {useEffect, useState} from 'react';
import _throttle from 'lodash/throttle';

export default function useWindowScroll() {
  const getScrollTop = () =>
    window.scrollY || document.documentElement.scrollTop || 0;
  const [scrollTop, setScrollTop] = useState(getScrollTop());

  const throttleSetScrollTop = _throttle(
    () => setScrollTop(getScrollTop()),
    100,
    {leading: false}
  );

  useEffect(() => {
    window.addEventListener('scroll', throttleSetScrollTop);
    window.visualViewport &&
      window.visualViewport.addEventListener('resize', throttleSetScrollTop);

    return () => {
      window.removeEventListener('scroll', throttleSetScrollTop);
      window.visualViewport &&
        window.visualViewport.removeEventListener(
          'resize',
          throttleSetScrollTop
        );
      throttleSetScrollTop.cancel();
    };
  }, []);

  return scrollTop;
}
