import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from "../../../../components/layout/DefaultConsumerLayout";

export default [
  <DefaultConsumerLayout
    key="/consumer/features/"
    exact
    path="/consumer/features/:feature"
    component={AsyncPage}
  />,
];
